body{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: linear-gradient(to bottom, #000000, #ffffff);

}

.movie-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movie-card {
  width: 30%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.movie-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.movie-card h4,
.movie-card h5,
.movie-card p {
  margin: 10px;
  text-align: center;
}

.movie-card-header{
  text-align: center;
  position: relative;
  top: 10px;
  color: #fff;
}

@media (max-width: 768px) {
  .movie-card {
    width: 45%;
    margin: 5px;
  }
  .movie-card img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 768px) {
  .movie-card {
    width: 30%;
    margin: 10px;
  }
  .movie-card img {
    height: 300px;
    object-fit: cover;
  }
}